.stats-bg {
  margin: 0 auto;
  position: relative;
}

.stats-bg .animate {
  position: absolute;
  width: 960px;
  height: 955px;
  top: -12px;
  animation: rotation 30s infinite linear;
  -webkit-animation: rotation 30s infinite linear;
  -moz-animation: rotation 30s infinite linear;
  -o-animation: rotation 30s infinite linear;
}

.stats-bg ul {
  position: relative;
  width: 350px;
  height: 350px;
  list-style: none;
}

.stats-bg ul li {
  position: absolute;
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #dadada;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 98px;
}

.stats-bg ul li img {
  max-width: 75px;
  max-height: 75px;

  transform: rotate(0deg);
  animation: counter-rotate 30s infinite linear;
}

/* Positioning elements in a structured circular way */
.stats-bg ul li:nth-child(1) {
  left: 189px;
  top: -3px;
}
.stats-bg ul li:nth-child(2) {
  left: 349px;
  top: -50px;
}
.stats-bg ul li:nth-child(3) {
  left: 514px;
  top: -45px;
}
.stats-bg ul li:nth-child(4) {
  left: 674px;
  top: 8px;
}
.stats-bg ul li:nth-child(5) {
  left: 794px;
  top: 111px;
}
.stats-bg ul li:nth-child(6) {
  left: 867px;
  top: 262px;
}
.stats-bg ul li:nth-child(7) {
  left: 894px;
  top: 429px;
}
.stats-bg ul li:nth-child(8) {
  left: 854px;
  top: 603px;
}
.stats-bg ul li:nth-child(9) {
  left: 738px;
  top: 760px;
}
.stats-bg ul li:nth-child(10) {
  left: 576px;
  top: 860px;
}
.stats-bg ul li:nth-child(11) {
  left: 338px;
  top: 889px;
}
.stats-bg ul li:nth-child(12) {
  left: 134px;
  top: 801px;
}
.stats-bg ul li:nth-child(13) {
  left: 0px;
  top: 640px;
}
.stats-bg ul li:nth-child(14) {
  left: -53px;
  top: 463px;
}
.stats-bg ul li:nth-child(15) {
  left: -29px;
  top: 271px;
}
.stats-bg ul li:nth-child(16) {
  left: 52px;
  top: 120px;
}

/* Rotating Animation */
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}

/* Counter Rotation for Images */
@keyframes counter-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes counter-rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}

@-moz-keyframes counter-rotate {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(-360deg);
  }
}

@-o-keyframes counter-rotate {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(-360deg);
  }
}
/* Ensure error text fits well */
input.border-red-500 {
  border-color: #ef4444;
}

/* Optional: Adjust spacing for smaller screens */
@media (max-width: 767px) {
  .text-red-500 {
    line-height: 1.2;
  }
}
/* Rich error message styling */
.error-message {
  display: flex;
  align-items: center;
  background-color: #fef2f2; /* Light red background */
  color: #dc2626; /* Tailwind's red-600 */
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #fecaca; /* Light red border */
  font-size: 14px;
  line-height: 1.4;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  word-wrap: break-word;

  svg {
    flex-shrink: 0;
    margin-right: 6px;
  }

  .error-link {
    color: #2563eb; /* Tailwind's blue-600 */
    text-decoration: underline;
    font-weight: 500;

    &:hover {
      color: #1e40af; /* Darker blue on hover */
      text-decoration: none;
    }
  }
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .error-message {
    font-size: 10px;
    padding: 4px 6px;

    svg {
      height: 12px;
      width: 12px;
      margin-right: 4px;
    }
  }
}

/* Input border when error */
input.border-red-500 {
  border-color: #dc2626;
}