.application-admission::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;
  left: 0;
  top: 0;
  opacity: 0.8;
}

.listMarker ul {
  list-style-type: none;
  display: flex;
  gap: 15px;
  flex-direction: column;
  li {
    position: relative;
    list-style-type: none;
    font-size: 14px;
    padding-left: 20px;
    &::before {
      content: "";
      position: absolute;
      background-image: url(../../images/application/marker-icon.svg);
      width: 13px;
      height: 13px;
      left: 0;
      top: 3px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.listMarker {
      ul {
          list-style: disc;
          margin-left: 1rem;
      }
      ol{
          list-style: decimal;
          margin-left: 1rem;
      }
      li{
          margin-bottom: 0.25rem;
      }


}