@keyframes ping-red {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  75%,
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.pulse-red-dot::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(239, 68, 68, 0.5);
  border-radius: 9999px;
  animation: ping-red 2s cubic-bezier(0, 0, 0.2, 1) infinite;
  z-index: -1;
}

.pulse-blue-dot::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(68, 128, 239, 0.5);
  border-radius: 9999px;
  animation: ping-red 2s cubic-bezier(0, 0, 0.2, 1) infinite;
  z-index: -1;
}
