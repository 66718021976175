.section {
  position: relative;
  opacity: 1;
  transition: opacity 0.5s ease, z-index 0.5s ease;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  .blue-header {
    background-color: #173cba;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0%;
    padding: 7px 17px;
    color: #fff;
    margin-bottom: 10px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0%;
    color: #383838;
  }
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0%;
    text-align: center;
    color: #173cba;
  }
  /* Add to your global styles.css or inside TabContent.module.css */
  table {
    width: 100%;
    border-collapse: collapse;
    border: 0.5px solid #383838;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    margin: 10px auto;
    th,
    td {
      border: 0.5px solid #383838;
      padding: 10px;
      text-align: left;
      width: 50%;
    }

    th {
      background-color: transparent;
      font-weight: 600;
    }
  }
}
.educationBoard ul::-webkit-scrollbar {
  height: 6px;
}

.educationBoard ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.educationBoard ul::-webkit-scrollbar-thumb {
  background-color: #00b489;
  border-radius: 10px;
}

.educationBoard ul::-webkit-scrollbar-thumb:hover {
  background-color: #00b489;
}
.educationBoard {
  .container {
    padding: 0;
  }
  .blue-header,
  h2 {
    background-color: #173cba;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0%;
    padding: 10px 17px;
    color: #fff;
    margin: 20px 0;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0%;
    color: #383838;
    margin-bottom: 12px;
  }
  p a {
    color: #173cba;
  }
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0%;
    text-align: left;
    color: #173cba;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border: 0.5px solid #383838;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    margin: 10px auto;
    th,
    td {
      border: 0.5px solid #383838;
      padding: 10px;
      text-align: left;
      width: 50%;
    }

    th {
      background-color: transparent;
      font-weight: 600;
    }
  }
  ul,
  ol {
    display: flex;
    padding-left: 20px;
    position: relative;
    flex-direction: column;
    list-style-type: none;
    gap: 12px;
    margin-bottom: 20px;
    li {
      display: flex;
      &::before {
        content: "";
        background-image: url(../../images/application/marker-icon.svg);
        width: 14px;
        height: 14px;
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        margin-top: 7px;
        min-width: 14px;
      }
    }
  }
  button {
    border: 1px solid #777777;
    padding: 10px;
    font-size: 14px;
    color: #173cba;
    border-radius: 3px;
    line-height: 20px;
    margin-bottom: 20px;
    margin-right: 24px;
    @media (width <= 767px) {
      background-color: #0c9;
      border: 1px solid #0c9;
      color: #fff;
    }
  }
}
.tableofContent::-webkit-scrollbar-thumb {
  background-color: #0c9;
}
.scroll-mt-16 {
  scroll-margin-top: 4rem;
}
.tableofContent::-webkit-scrollbar {
  width: 6px;
}
.tableofContent::-webkit-scrollbar-thumb {
  background-color: #0c9;
  border-radius: 10px;
}
.tableofContent::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 10px;
}
.tableofContent {
  max-height: 345px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  ul {
    display: flex;
    padding-left: 20px;
    position: relative;
    flex-direction: column;
    list-style-type: none;
    li {
      display: flex;
      margin: 0 !important;
      &::before {
        content: "";
        background-image: url(../../images/application/marker-icon.svg);
        width: 14px;
        height: 14px;
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        margin-top: 3px;
        min-width: 14px;
      }
    }
  }
}

.educationBoard a {
  color: #173cba;  ; /* Or your preferred link color, e.g., theme('colors.blue.600') if using Tailwind JIT/config */
  cursor: pointer; /* Ensure the cursor changes on hover */
}
