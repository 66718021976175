.certificate {
    width: 600px;
    /* Custom width */
    height: 440px;
    /* Custom height */
    position: relative;
    box-sizing: border-box;
}

.template-image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.content {
    position: absolute;
    width: 100%;
    text-align: center;
    font-family: 'Bembo Std', 'Times New Roman', serif;
    color: #000;
    z-index: 2;
}

.presented-to {
    top: 220px;
    position: absolute;
    width: 100%;
    line-height: 1.7;
    margin: 0;
    color: #000;
    padding: 5px 0;
}

.award-details {
    top: 260px;
    position: absolute;
    width: 100%;
    font-size: 16px;
    line-height: 1.7;
    margin: 0;
    color: #000;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px 0;
}

.name {
    font-weight: bold;
    display: inline-block;
    min-width: 150px;
    border-bottom: 1px dotted #000;
}

/* Style the download button */
.download-button {
    display: block;
    margin: 20px auto;
    /* Center the button below the certificate */
    padding: 10px 20px;
    background-color: #000080;
    /* Navy blue to match theme */
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0000b3;
        /* Slightly lighter navy on hover */
    }
}

@media print {
    .certificate {
        width: 210mm;
        height: 297mm;
        margin: 0;
        transform: scale(0.35);
        transform-origin: top left;
    }

    .download-button {
        display: none;
        /* Hide button when printing */
    }

    @page {
        margin: 0;
    }
}
.certificate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
}

.form-container {
    margin-bottom: 20px;
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.form-group input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-group input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}


.certificate-wrapper {
    position: relative;
    width: 100%;
    max-width: 800px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.certificate-image {
    width: 100%;
    height: auto;
    display: block;
}

.certificate-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.certificate-text span {
    position: absolute;
    font-family: 'Times New Roman', Times, serif;
    
}

/* Adjusted positions and font styles to match the certificate */
.presented-to {
    top: 50%;
    left: 42%;
    color: #173CBA;
    font-size: 16px;
    font-weight: normal;
}

.content {
    top: 57.5%;
    left: 0%;
    color: #173CBA;
    font-size: 16px;
    font-weight: normal;
}

.financial-award {
    top: 65%;
    color: #173CBA;
    left: 27%;
    font-size: 16px;
    font-weight: normal;
}


.signature {
    position: absolute;
    bottom: 117px;
    right: 341px;
    width: 126px;
    height: auto;
}
.certificate-id {
    bottom: 16%;
    left: 10%;
    color: black;
    font-size: 16px;
    font-weight: normal;
}

.date {
    bottom: 17%;
    right: 9%;
    color: black;
    font-size: 16px;
    font-weight: normal;
}
.image-content {
    position: absolute;
    bottom: 173px;
    right: 69px;
    width: 83%;
    height: auto;
}