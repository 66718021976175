.booking-form {
    max-width: 600px;
    margin: 0 0 2rem 0;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .booking-form h3 {
    margin-bottom: 1rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .booking-form select {
    padding: 0.5rem;
    width: 100%;
    box-sizing: border-box;
  }
  
  .booking-form button {
    padding: 0.5rem;
    width: 100%;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .booking-form button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .booking-form button:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  .booking-form .error {
    color: red;
    margin-top: 0.5rem;
  }
  
  .booking-form .loading {
    color: #555;
  }