/* StudentDashboard.css */
.student-dashboard {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.student-dashboard h2 {
  color: #333;
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
}

.loading {
  color: #666;
  font-style: italic;
  text-align: center;
}

.error {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 20px;
}

/* Booking Details Section */
.booking-details {
  margin-top: 30px;
}

.booking-details h3 {
  color: #444;
  font-size: 22px;
  margin-bottom: 15px;
  border-bottom: 2px solid #eee;
  padding-bottom: 5px;
}

.booking-list {
  list-style: none;
  padding: 0;
}

.booking-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.booking-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.booking-item p {
  margin: 5px 0;
  color: #555;
}

.booking-item p strong {
  color: #333;
}

.booking-item a {
  color: #1976d2;
  text-decoration: none;
}

.booking-item a:hover {
  text-decoration: underline;
}

/* Status-specific styling */
.booking-item.pending {
  border-left: 4px solid #ffa726;
}

.booking-item.approved {
  border-left: 4px solid #4caf50;
}

.booking-item.cancelled {
  border-left: 4px solid #d32f2f;
  opacity: 0.7;
}

/* Button Styling */
.booking-item button {
  background-color: #d32f2f;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.booking-item button:hover:not(:disabled) {
  background-color: #b71c1c;
}

.booking-item button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 600px) {
  .booking-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .booking-item button {
    margin-top: 10px;
    width: 100%;
  }

  .student-dashboard {
    padding: 10px;
  }
}